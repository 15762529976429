import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  toggleSticky() {
    if(document.body.scrollTop.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
      this.element.classList.add("nav-sticky");
    }
    else {
      this.element.classList.remove("nav-sticky");
    }
  }
}
