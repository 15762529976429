import { Controller } from "@hotwired/stimulus"
import confetti from "canvas-confetti"

export default class extends Controller {
  static targets = ["container", "source", "form", "thankYou", "name", "phone", "clients", "percentage"]

  show({ params: { source } }) {
    this.sourceTarget.value = source;

    gtag('event', 'contact_modal_open', { source })

    this.containerTarget.classList.remove("hidden")
  }

  updateValue({detail: { clients, percentage }}) {
    this.clientsTarget.value = clients;
    this.percentageTarget.value = percentage;
  }

  send() {
    if (this.validate()) {
      fetch(this.formTarget.action, {
        method: "POST",
        body: new FormData(this.formTarget),
        mode: "no-cors",
      }).then(() => {
        this.renderThankYouMessage();
      });
    }
  }

  validate() {
    if(this.nameTarget.value.length >= 3 && this.phoneTarget.value.length >= 6) {
      return true;
    }
    else {
      return false;
    }
  }

  renderThankYouMessage() {
    this.formTarget.classList.add("hidden");
    this.thankYouTarget.classList.remove("hidden");
    confetti();
  }

  hide() {
    this.containerTarget.classList.add("hidden")
  }
}
