import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["container", "toggle"]

  toggleMenu() {
    this.toggleTarget.classList.toggle('open');

    if(this.containerTarget.style.display === "block") {
      this.containerTarget.style.display = "none";
    }
    else {
      this.containerTarget.style.display = "block";
    }
  }
}
