
        import * as module0 from './controllers/contact_modal_controller.js';import * as module1 from './controllers/income_calculator_controller.js';import * as module2 from './controllers/jarallax_controller.js';import * as module3 from './controllers/menu_controller.js';import * as module4 from './controllers/top_nav_controller.js'
        const modules = {
            "./controllers/contact_modal_controller.js": module0,
            "./controllers/income_calculator_controller.js": module1,
            "./controllers/jarallax_controller.js": module2,
            "./controllers/menu_controller.js": module3,
            "./controllers/top_nav_controller.js": module4,
        };
        export default modules;
      