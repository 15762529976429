import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [
    "clientSelector",
    "percentageSelector",
    "value",
    "header",
    "cta",
    "modal",
    "productIncome",
    "scansIncome",
    "bonusIncome",
    "totalIncome",
    "clientsContainer",
    "bonusContainer",
    "bonusWarning",
    "upscaledProductIncome",
    "upscaledScansIncome",
    "upscaledRescansIncome",
    "upscaledBonusIncome",
    "upscaledTotalIncome",
    "upscaledClientsContainer",
    "upscaledBonusContainer",
    "upscaledBonusWarning",
    "newClientsContainer"
  ]


  connect() {
    this.productIncome = 0;
    this.scansIncome = 0;
    this.bonusIncome = 0;
    this.totalClients = 0;
    this.upscaledTotalClients = 0;

    this.moneyFormatter = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', roundingMode: 'floor', minimumFractionDigits: 0});

    this.updateValue(false);
  }

  getBonus(value) {
    switch (true) {
      case (value < 40):
        return null;
      case (value < 50):
        return 538.12;
      case (value < 60):
        return 813.12;
      case (value < 70):
        return 1177.35;
      case (value < 80):
        return 1669.28;
      case (value < 90):
        return 2208.70;
      case (value < 100):
        return 2786.28;
      case (value < 110):
        return 3362.53;
      case (value < 120):
        return 3899.10;
      case (value < 130):
        return 4414.13;
      case (value < 140):
        return 5013.95;
      case (value < 150):
        return 5664.90;
      case (value < 160):
        return 6269.61;
      case (value < 170):
        return 6925.92;
      case (value < 180):
        return 7582.23;
      case (value < 190):
        return 8238.54;
      case (value < 200):
        return 8894.85;
      case (value < 210):
        return 9551.16;
      case (value < 220):
        return 10207.47;
      case (value < 230):
        return 10863.78;
      case (value < 240):
        return 11520.09;
      case (value < 250):
        return 12176.40;
      case (value < 260):
        return 12832.71;
    }
  }

  updateValue(triggerEvent = true) {
    const clients = parseInt(this.clientSelectorTarget.value);
    const percentage = parseFloat(this.percentageSelectorTarget.value);

    if(clients < 250) {
      this.renderCalculation(clients, percentage)
    }
    else {
      this.renderCTA();
    }

    if (triggerEvent) {
      gtag('event', 'income_calculator_change', { clients, percentage })
      this.dispatch("update", { detail: { clients, percentage }})
    }
  }

  renderCalculation(clients, percentage) {
    const productPrice = 12.16;
    const scanPrice = 35;

    this.totalClients = Math.floor(clients * (percentage / 100));
    this.newClients = Math.ceil(Math.floor(this.totalClients * 1.1) / 100) * 10;
    this.upscaledTotalClients = this.totalClients + this.newClients;

    this.productIncome = this.totalClients * productPrice;
    this.upscaledProductIncome = this.upscaledTotalClients * productPrice;

    this.scansIncome = this.totalClients * scanPrice;
    this.upscaledScansIncome = this.newClients * scanPrice;
    this.upscaledRescansIncome = this.totalClients * scanPrice;

    this.bonusIncome = this.getBonus(this.totalClients);
    this.upscaledBonusIncome = this.getBonus(this.upscaledTotalClients);

    this.totalIncome = this.productIncome + this.scansIncome + this.bonusIncome;
    this.upscaledTotalIncome = this.upscaledProductIncome + this.upscaledScansIncome + this.upscaledRescansIncome + this.upscaledBonusIncome;

    const formattedValue = this.moneyFormatter.format(this.totalIncome);

    this.valueTarget.textContent = formattedValue;
    this.ctaTarget.classList.add("hidden");
    this.headerTarget.classList.remove("hidden");
  }

  renderCTA() {
    this.headerTarget.classList.add("hidden");
    this.ctaTarget.classList.remove("hidden");
  }

  updatePercentage({ params: { percentage } }) {
    this.percentageSelectorTarget.value = percentage;
    this.updateValue();
  }

  updateClients({ params: { clients } }) {
    this.clientSelectorTarget.value = clients;
    this.updateValue();
  }

  showModal() {
    this.clientsContainerTargets.forEach((el) => { el.textContent = this.totalClients; });

    this.productIncomeTarget.textContent = this.moneyFormatter.format(this.productIncome);
    this.scansIncomeTarget.textContent = this.moneyFormatter.format(this.scansIncome);
    this.bonusIncomeTarget.textContent = this.moneyFormatter.format(this.bonusIncome);
    this.totalIncomeTarget.textContent = this.moneyFormatter.format(this.totalIncome);

    this.upscaledProductIncomeTarget.textContent = this.moneyFormatter.format(this.upscaledProductIncome);
    this.upscaledScansIncomeTarget.textContent = this.moneyFormatter.format(this.upscaledScansIncome);
    this.upscaledRescansIncomeTarget.textContent = this.moneyFormatter.format(this.upscaledRescansIncome);
    this.upscaledBonusIncomeTarget.textContent = this.moneyFormatter.format(this.upscaledBonusIncome);
    this.upscaledTotalIncomeTarget.textContent = this.moneyFormatter.format(this.upscaledTotalIncome);

    this.newClientsContainerTarget.textContent = this.newClients;

    gtag('event', 'income_calculator_summary', { clients: this.totalClients , totalIncome: this.totalIncome, upscaledTotalIncome: this.upscaledTotalIncome });

    if(this.bonusIncome === null) {
      this.showBonusWarning();
    }
    else {
      this.showBonus();
    }

    if (this.upscaledBonusIncome === null) {
      this.hideUpscaledBonus();
    }
    else {
      this.showUpscaledBonus();
    }


    this.modalTarget.classList.remove("hidden");
  }

  hideModal() {
    console.log("click")
    this.modalTarget.classList.add("hidden");
  }

  showBonusWarning() {
    this.bonusWarningTarget.classList.remove("hidden");
    this.bonusContainerTarget.classList.add("hidden");
  }

  showBonus() {
    this.bonusWarningTarget.classList.add("hidden");
    this.bonusContainerTarget.classList.remove("hidden");
  }

  hideUpscaledBonus() {
    this.upscaledBonusContainerTarget.classList.add("hidden");
  }

  showUpscaledBonus() {
    this.upscaledBonusContainerTarget.classList.remove("hidden");
  }
}
